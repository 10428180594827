<template lang="pug">
  div.wrap-firebase-signin-ui
    //- div(id="firebaseui-auth-container")
    div(:id="authContainerId")
</template>

<style lang="scss">
.wrap-firebase-signin-ui {
  .firebaseui-idp-list {
    padding: 0 !important;
  }
  .firebaseui-card-footer {
    display: none;
  }
  .firebaseui-idp-button {
    max-width: initial !important;
    min-width: 260px;
    .firebaseui-idp-text-long {
      text-align: center;
      min-width: 192px;
    }
  }
  .firebaseui-idp-text-long {
    font-size: 13px;
  }
}
</style>

<script>
import { scopes } from '@/components/utils/config'
import { firebase } from '@/components/utils/firebase'
// import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

export default {
  metaInfo() {
    // skip if firebaseui is loaded
    if (window.firebaseui) {
      return {}
    }

    return {
      script: [
        {
          callback: () => {
            window.firebase = firebase
            this.setupSignInUi()
          },
          src: this.scriptSource,
          body: true,
          once: true
        }
      ]
    }
  },
  props: {
    googleSignInLabel: {
      type: String,
      default: null
    },
    authContainerId: {
      type: String,
      default: 'firebaseui-auth-container'
    }
  },
  data() {
    return {
      isSignInUILoaded: false
    }
  },
  computed: {
    scriptSource() {
      const browserLocale = navigator.language.split('-')[0]
      return browserLocale === 'ja'
        ? 'https://www.gstatic.com/firebasejs/ui/4.8.0/firebase-ui-auth__ja.js'
        : 'https://www.gstatic.com/firebasejs/ui/4.8.0/firebase-ui-auth__en.js'
    }
  },
  methods: {
    setupSignInUi() {
      const vInstance = this
      const uiConfig = {
        credentialHelper: window.firebaseui.auth.CredentialHelper.NONE,
        callbacks: {
          uiShown() {
            vInstance.isSignInUILoaded = true
          },
          signInSuccessWithAuthResult: (authResult) => {
            this.$emit('signInSuccess', {
              firebaseUser: authResult.user,
              token: authResult.credential
            })
            return false
          },
          signInFailure(code) {
            console.log('signInFailure', code)
          }
        },
        autoUpgradeAnonymousUsers: true, // アノニマスユーザーを許可
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          // {
          //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          //   requireDisplayName: true
          // },
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            scopes,
            fullLabel: this.googleSignInLabel
          }
          // {
          //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          //   fullLabel: 'This is full label'
          // }
          // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
          // firebase.auth.GithubAuthProvider.PROVIDER_ID,
          // firebase.auth.EmailAuthProvider.PROVIDER_ID,
          // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: 'https://www.meetawesome.jp/termsofservice',
        // Privacy policy url/callback.
        privacyPolicyUrl: function () {
          location.href = 'https://www.meetawesome.jp/privacypolicy'
        }
      }

      // Initialize the FirebaseUI Widget using Firebase.

      // The start method will wait until the DOM is loaded.

      // this.$nextTick(() => {
      //   ui.start('#firebaseui-auth-container', uiConfig)
      // })

      // setTimeout(() => {
      if (!window.firebaseauthui) {
        window.firebaseauthui = new window.firebaseui.auth.AuthUI(firebase.auth())
      }
      window.firebaseauthui.start(`#${this.authContainerId}`, uiConfig)
      // }, 800)
    }
  },
  mounted() {
    if (window.firebaseui) {
      this.setupSignInUi()
    }
  }
}
</script>

<style>
/* Collapse height of firebase ui loading spinner container */
.mdl-card .firebaseui-callback-indicator-container {
  height: 2px;
}

/* Collapse height of firebase ui loading spinner container */
.mdl-card.firebaseui-container {
  min-height: 2px;
}
</style>
